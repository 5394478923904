.repo-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  cursor: pointer;
  flex: 1 1 25%;
}
.repo-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo-stats {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-size: 13px;
  color: rgb(106, 115, 125);
}

.repo-left-stat {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
}

.language-color {
  width: 10px;
  height: 10px;
  background-color: blue;
  margin-right: 0.25rem;
  border-radius: 100%;
}

.repo-left-stat span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 0.75rem;
}

.repo-name-div {
  display: flex;
  align-items: center;
}

.repo-svg {
  margin-right: 0.5rem;
  min-width: 16px;
}

.repo-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.repo-star-svg {
  margin-right: 0.3rem;
}

.repo-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

.repo-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

.repo-languages {
  margin-left: auto;
  margin-right: 0px;
}

@media (max-width: 1200px) {
  .repo-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .repo-card-div {
    flex: 1 1 100%;
  }

  .repo-name {
    font-size: 16px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }

  .repo-creation-date {
    font-size: 14px;
  }
}


.card {
  width: 300px;
  height: 200px;
  position: relative;
  display: grid;
  place-content: center;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

#logo-main, #logo-second {
  height: 100%;
}

#logo-main {
  fill: #bd9f67;
}

#logo-second {
  padding-bottom: 10px;
  fill: none;
  stroke: #bd9f67;
  stroke-width: 1px;
}

.border {
  position: absolute;
  inset: 0px;
  border: 2px solid;
  border-radius: 10px;
  opacity: 0;
  transform: rotate(10deg);
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.bottom-text {
  position: absolute;
  left: 50%;
  bottom: 13px;
  transform: translateX(-50%);
  font-size: 8px;
  text-transform: uppercase;
  padding: 0px 5px 0px 8px;
  background: #243137;
  opacity: 0;
  letter-spacing: 7px;
  transition: all 0.5s ease-in-out;
  white-space: nowrap;
}

.content {
  transition: all 0.5s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content .logo-bottom-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 30px;
  padding-left: 8px;
  font-size: 11px;
  opacity: 0;
  letter-spacing: none;
  transition: all 0.5s ease-in-out 0.5s;
}

.card:hover {
  border-radius: 10px;
  transform: scale(1.1);
}

.card:hover .content {
  width: 134px;
  display: none;
}

.card:hover .border {
  inset: 15px;
  opacity: 1;
  transform: rotate(0);
}

.card:hover .bottom-text {
  letter-spacing: 3px;
  opacity: 1;
  transform: translateX(-50%);
}

.card:hover .content .logo-bottom-text {
  opacity: 1;
  letter-spacing: 9.5px;
}

@keyframes opacity {
  0% {
    border-right: 1px solid transparent;
  }

  10% {
    border-right: 1px solid #bd9f67;
  }

  80% {
    border-right: 1px solid #bd9f67;
  }

  100% {
    border-right: 1px solid transparent;
  }
}

.repo-icon{
  width: 40px;
  height: 40px;
}

.repo-name{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}