/* Tray.css */
.tray {
  position: fixed;
  left: 0;
  top: 50%;
  width: 350px;
  height: 200px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: white;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%); /* Start hidden */
}

.tray.open {
  transform: translateX(0); /* Slide into view */
}

.tray-icon {
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px;
  padding-left: 20px;
  cursor: pointer;
  animation: vibrate-expand 0.3s ease-in-out infinite alternate;
  animation-delay: 0s; /* Delay to start immediately */
  animation-duration: 3s; /* Runs every 3 seconds */
  animation-timing-function: ease-in-out;
}

.tray-icon-x {
  position: absolute;
  right: -2px;
  top: -9%;
  transform: translateY(50%);
  padding: 5px;
  cursor: pointer;
}

.tray-content {
  padding: 20px;
}

@keyframes vibrate-expand {
  0%,
  100% {
    transform: scale(1.2) translateX(0);
  }
  20% {
    transform: scale(1.2) translateX(-2px);
  }
  40% {
    transform: scale(1.2) translateX(2px);
  }
  60% {
    transform: scale(1.2) translateX(-2px);
  }
  80% {
    transform: scale(1.2) translateX(2px);
  }
}
