/* header */

.header {
  display: block;
  max-width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  /* background-color: #fff; */
}
.grey-color {
  color: #868e96;
}

.header li a {
  display: block;
  padding: 20px 20px;
  /* color: black; */
  text-decoration: none;
  border-radius: 10px;
}

.header li a:hover,
.header .menu-btn:hover {
  /* background-color: #A6E1FA; */
}

.header .logo {
  display: block;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
}
.header .logo-name {
  font-family: "montserrat";
  font-weight: bold;
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.header .logo-name span {
  display: inline-block;
  line-height: 1.8;
  padding: 0 8px;
}

/* menu */

.menu {
  font-family: "Google Sans Regular";
}

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 370px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}
@media (max-width: 960px) and (min-width: 768px) {
  .header {
    font-size: 12px;
  }
}

.themeMenu {
  background-color: white;
  border-radius: 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-width: 150px;
  max-width: 190px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.themeMenuInner {
  margin: 10px;
  margin-top: unset;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  gap: 0.25rem;
}

.themeMenuInner .th-item {
  display: flex;
  line-height: 1;
  gap: 0.25rem;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.45rem;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 20px;
  border: 1px solid gray;
}

.themeMenuInner .th-item.active {
  border: 2px solid black;
}

.color {
  width: 12px;
  height: 12px;
  border-radius: 20px;
  background-color: red;
}

.loader {
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #868e96;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: -5px -5px 5px rgba(255, 255, 255, 0.1),
    10px 10px 10px rgba(0, 0, 0, 0.4),
    inset -5px -5px 5px rgba(255, 255, 255, 0.2),
    inset 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.loader:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
  z-index: 10;
  background: rgb(88, 96, 105);
  border-radius: 50%;
  border: 2px solid #868e96;
  box-shadow: inset -2px -2px 5px rgba(255, 255, 255, 0.2),
    inset 3px 3px 5px rgba(0, 0, 0, 0.5);
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 50%;
  background-image: linear-gradient(
    -225deg,
    #ff7402 0%,
    #ffe700 50%,
    #fff55e 100%
  );
  filter: blur(20px);
  animation: animate 0.5s linear infinite, hue 7s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes hue {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.themeName {
  color: #000;
}

.pyramid-loader {
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
  transform-style: preserve-3d;
  transform: rotateX(-20deg);
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotateY(360deg);
  }
}

.pyramid-loader .wrapper .side {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform-origin: center top;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.pyramid-loader .wrapper .side1 {
  transform: rotateZ(-30deg) rotateY(90deg);
  background: conic-gradient(#2bdeac, #f028fd, #d8cce6, #2f2585);
}

.pyramid-loader .wrapper .side2 {
  transform: rotateZ(30deg) rotateY(90deg);
  background: conic-gradient(#2f2585, #d8cce6, #f028fd, #2bdeac);
}

.pyramid-loader .wrapper .side3 {
  transform: rotateX(30deg);
  background: conic-gradient(#2f2585, #d8cce6, #f028fd, #2bdeac);
}

.pyramid-loader .wrapper .side4 {
  transform: rotateX(-30deg);
  background: conic-gradient(#2bdeac, #f028fd, #d8cce6, #2f2585);
}

.pyramid-loader .wrapper .shadow {
  background: #8b5ad5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform: rotateX(90deg) translateZ(-40px);
  filter: blur(12px);
}
