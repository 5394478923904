.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  font-family: "Google Sans Regular";
}

.software-skill-inline > i {
  color: #868e96;
}

.software-skill-inline > i:hover {
  color: #645beb;
}

.skill-image {
  height: 48px;
}

#tooltip-top.my-custom-tooltip > .tooltip-inner {
  background-color: unset;
  color: unset;
  border: unset;
}

#tooltip-top.my-custom-tooltip > .tooltip-arrow {
  border-top: unset;
}

.tooltiptext {
  height: 3em;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6); 
  backdrop-filter: blur(10px); 
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  left: -2.8em;
  top: -3.5em;
  z-index: 1;
  transition: .1s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #333;
}

.tooltiptext .skillname{
  white-space: nowrap;
}