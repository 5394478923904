.degree-card {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-family: "Google Sans Medium";
  font-size: 23px;
  /* color: #000000; */
}

.card-subtitle {
  font-family: "Google Sans Medium";
  font-size: 18px;
  /* color: #000000; */
  margin-top: 7px;
  margin-bottom: 5px;
}

.card-img {
  width: 200px;
  height: auto;
  border-radius: 50%;
  padding: 10px;
  border: 1px solid #d9dbdf;
  /* background-color: #6c63ff; */
  /* background: transparent; */
  box-shadow: 5px 5px 5px #d9dbdf;
  overflow: hidden;
}

.body-header {
  max-width: inherit;
  display: flex;
  border-radius: 7px 7px 0px 0px;
  padding: 10px;
  padding-bottom: 0px;
}

.head {
  max-width: inherit;
  display: flex;
  flex: 1;
  border-radius: 7px;
  padding: 10px;
  transition: 0.3s ease;
  padding-top: 0px;
}

.head:hover {
  transform: translateY(-25%);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 13px 47px -5px,
    rgba(0, 0, 0, 0.2) 0px 8px 16px -8px;
}

.body-header-title {
  align-items: flex-start;
  width: 80%;
}

.body-header-duration {
  width: 20%;
}
.duration {
  font-family: "Google Sans Regular";
  font-size: 16px;
  /* color: #212121; */
  padding-right: 10px;
  float: right;
}

.body-content {
  max-width: inherit;
  /* width: 100%; */
  border-radius: 0px 0px 7px 7px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  /* box-shadow: 5px 5px 5px #d3d3d3; */
}

.content-list {
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Google Sans Regular";
}

.card-body {
  border: 1px solid #d9dbdf;
  border-radius: 7px;
  margin: 10px;
  box-shadow: 5px 5px 5px #d9dbdf;
}

.visit-btn {
  display: inline-block;
  margin: 0px 10px 10px 0px;
  padding: 10px;
  border-radius: 7px;
  border: 0px;
  float: right;
}

.visit-btn:hover {
  /* background-color:#6c63ffcc; */
}

.btn {
  margin: 0px;
  padding: 0px;
  font-family: "Google Sans Regular";
  /* color: #000000; */
}

@media (max-width: 1380px) {
}

@media (max-width: 768px) {
  .degree-card {
    flex-direction: column;
  }

  .body-header {
    flex-direction: column;
  }

  .body-header-title {
    width: 100%;
  }

  .body-header-duration {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .duration {
    padding: 0px;
    margin: 0px;
    float: left;
  }

  .card-img {
    width: 200px;
  }

  .card-body {
    width: 100% !important;
  }

  .card-title {
    font-size: 20px;
  }

  .card-subtitle {
    font-size: 16px;
  }
}

.button {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  border-width: 0;
  padding: 0 8px 12px;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
}

.button-top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;
  padding: 8px 16px;
  transform: translateY(0);
  text-align: center;
  color: #fff;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.25);
  transition-property: transform;
  transition-duration: 0.2s;
  -webkit-user-select: none;
  user-select: none;
}

.button:active .button-top {
  transform: translateY(6px);
}

.button-top::after {
  content: "";
  position: absolute;
  z-index: -1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  box-sizing: content-box;

  text-align: center;
  color: #fff;
  box-shadow: inset 0 0 0px 1px rgba(255, 255, 255, 0.2),
    0 1px 2px 1px rgba(255, 255, 255, 0.2);
  transition-property: border-radius, padding, width, transform;
  transition-duration: 0.2s;
}

.button:active .button-top::after {
  border-radius: 6px;
  padding: 0 2px;
}

.button-bottom {
  position: absolute;
  z-index: -1;
  bottom: 4px;
  left: 4px;
  border-radius: 8px / 16px 16px 8px 8px;
  padding-top: 6px;
  width: calc(100% - 8px);
  height: calc(100% - 10px);
  box-sizing: content-box;
  background-color: var(--bgclr);

  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5),
    inset 0 -1px 3px 3px rgba(0, 0, 0, 0.4);
  transition-property: border-radius, padding-top;
  transition-duration: 0.2s;
}

.button:active .button-bottom {
  border-radius: 10px 10px 8px 8px / 8px;
  padding-top: 0;
}

.button-base {
  position: absolute;
  z-index: -2;
  top: 4px;
  left: 0;
  border-radius: 12px;
  width: 100%;
  height: calc(100% - 4px);
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 1px 0 rgba(255, 255, 255, 0.75),
    inset 0 2px 2px rgba(0, 0, 0, 0.25);
}
