.publication-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  padding: 2rem;
  cursor: pointer;
  border-radius: 10px;
  flex: 1 1 25%;
}
.publication-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.publication-name-div {
  display: flex;
  align-items: center;
}

.publication-name {
  text-align: left;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 25px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  line-height: 1.2;
  margin: 0px;
}

.publication-description {
  overflow: hidden;
  text-align: left;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 0.8rem;
}

.publication-details {
  display: flex;
  flex-direction: row;
}

.publication-creation-date {
  margin: 0;
  font-family: "Google Sans Regular";
  font-weight: 400;
  margin-left: 0px;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .publication-card-div {
    flex: 1 1 40%;
  }
}

@media (max-width: 768px) {
  .publication-card-div {
    flex: 1 1 100%;
  }

  .publication-name {
    font-size: 16px;
  }

  .publication-description {
    font-size: 14px;
  }

  .publication-details {
    flex-direction: column;
  }

  .publication-creation-date {
    font-size: 14px;
  }
}

.publication-icon{
  width: auto;
  height: 40px;
  margin: 10px;
}

.publication-card-div{
  border-radius: 20px;
  background: #1b233d;
  padding: 5px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.publication-card-div .top-section {
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.publication-card-div .top-section .border {
  border-bottom-right-radius: 10px;
  height: 30px;
  width: 130px;
  background: var(--before-color);
  position: relative;
  transform: skew(-40deg);
  box-shadow: -10px -10px 0 0 var(--before-color);
  border: unset;
  opacity: 1;
}

.publication-card-div .top-section .border::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  right: -15px;
  background: rgba(255, 255, 255, 0);
  border-top-left-radius: 10px;
  box-shadow: -5px -5px 0 2px var(--before-color);
}

.publication-card-div .top-section::before {
  content: "";
  position: absolute;
  top: 20px;
  left: 0;
  background: rgba(255, 255, 255, 0);
  height: 15px;
  width: 15px;
  border-top-left-radius: 15px;
  box-shadow: -5px -5px 0 2px var(--before-color);
}

.pubCard{
  display: flex;
  flex-direction: column;
  flex: 1;
}