@import url("https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap");

.watch {
  display: flex;
  flex-direction: column;
  align-items: end;
}

#time {
  display: flex;
  gap: 1.5rem;
}

#time .circle {
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#time .circle svg {
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
  transform: rotate(270deg);
}

#time .circle svg circle {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke: #191919;
  stroke-width: 4;
}

#time .circle svg circle:nth-child(2) {
  stroke: var(--clr);
  stroke-dasharray: 252;
  stroke-width: 5px;
}

#time div {
  position: absolute;
  text-align: center;
  font-weight: 500;
  font-size: 1.25em;
}

#time div span {
  position: absolute;
  transform: translate(-50%, -10px);
  font-size: 0.35em;
  font-weight: 300;
  letter-spacing: 0.1em;
  margin-top: 0.4rem;
  text-transform: uppercase;
}

#time .ap {
  position: relative;
  font-size: 1em;
  transform: translateX(-20px);
}

.dots {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
}

.dots::before {
  content: "";
  position: absolute;
  top: -2px;
  width: 0.75rem;
  height: 0.75rem;
  background: var(--clr);
  border-radius: 50%;
  box-shadow: 0 0 20px var(--clr), 0 0 60px var(--clr);
}

.weeks {
  display: flex;
  gap: 0.25rem;
  margin: 0.5rem 0;
  max-width: 375px;
}

.weeks div {
  flex: 1;
  opacity: 0.25;
  text-align: center;
}

#date {
  text-align: center;
  max-width: 375px;
  margin: 10px 0;
}
