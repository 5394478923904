.contact-main {
  width: 100%;
}

.basic-contact {
  margin-left: 5%;
  margin-right: 5%;
}

.contact-heading-div {
  display: flex;
}

.blog-heading-div {
  display: flex;
}

.address-heading-div {
  display: flex;
  margin-top: 80px;
}

.blog-heading-div > * {
  flex: 1;
}

.contact-heading-div > * {
  flex: 1;
}

.address-heading-div > * {
  flex: 1;
}

.contact-heading-img-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-heading-img-div {
  align-items: center;
  justify-content: center;
}

.contact-heading-text-div {
  text-align: center;
}

.blog-heading-text-div {
  text-align: center;
}

.address-heading-text-div {
  text-align: center;
}

.contact-heading-img-div > * {
  max-width: 100%;
  height: auto;
  max-height: 500px;
}

.blog-heading-img-div > * {
  max-width: 100%;
  height: auto;
}

.contact-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 60px;
}

.blog-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 40px;
}

.address-heading-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.contact-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.blog-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.resume-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blogsite-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.address-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

@media (max-width: 1380px) {
  .contact-heading-text {
    font-size: 50px;
    margin-top: 60px;
  }

  .blog-heading-text {
    font-size: 50px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .contact-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .blog-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .address-heading-text {
    font-size: 20px;
  }

  .blog-heading-div {
    flex-direction: column-reverse;
    margin-top: 60px;
  }

  .address-heading-div {
    flex-direction: column;
    margin-top: 60px;
  }

  .blog-heading-text-div {
    margin-top: 40px;
  }

  .address-heading-text-div {
    margin-top: 40px;
  }

  .contact-heading-div {
    flex-direction: column;
  }

  .contact-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .blog-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}

.round {
  object-fit: round;
  border-radius: 50%;
}

.contact-form .form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 20px;
  border: 1px solid black;
  border-radius: 10px;
}

.contact-form .form .title {
  color: royalblue;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-form .form input {
  outline: 0;
  border: 1px solid rgb(219, 213, 213);
  padding: 8px 14px;
  border-radius: 8px;
  width: 100%;
  height: 50px;
  background: transparent;
  font-family: monospace;
}

.contact-form .form textarea {
  border-radius: 8px;
  height: 100px;
  width: 100%;
  resize: none;
  outline: 0;
  padding: 8px 14px;
  border: 1px solid rgb(219, 213, 213);
  background: transparent;
}

.m-auto {
  margin: auto;
}

.contact-form .main-button:hover {
  background-color: transparent !important;
  color: var(--text) !important;
}

.contact-form .form .has-error {
  border: 1px solid red !important;
}
