.logo_wrapper {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  /* background-color: #55198b; */
  overflow: hidden;
  border-radius: 100%;
  animation: grow 5.5s forwards;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  90% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
    border-radius: 100%;
  }
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container{
  color: white;
  position: relative;
  width: 225px;
  height: 225px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  border-radius: 50%;
}

.logo{
  font-family: "sans-serif";
  font-size: 184.2px;
}



.logo-text{
  font-family: "montserrat";
  font-size: 29px;
  position: absolute;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 2px solid white;
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 2.5s steps(30, end),
    blink-caret .5s step-end infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: calc(100% + 100px) }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}

.logo-icon{
  height: 300px;
  width: 300px;
}