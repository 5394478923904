#topButton {
  visibility: hidden;
  position: fixed;
  bottom: 1.2rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 15px;
  outline: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 1.5rem;
}

#topButton:hover #arrow {
  animation: slide-in-bottom .7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}