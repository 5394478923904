.main-button {
  background-color: #55198b;
  border: solid 1px #55198b;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  width: max-content;
  padding: 13px 22px;
  margin-right: 0px;
  /* text-transform: uppercase; */
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  font-family: "Google Sans Regular";
  display: block;
  margin-top: 20px;
  font-size: 18px;
  cursor: pointer;
  letter-spacing: 2px;
}
.main-button:hover {
  /* background-color: #ffffff; */
  /* color: black; */
  transition: ease-in 0.3s;
}
.project-button {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
/* Media Query */
@media (max-width: 768px) {
  .main-button {
    font-size: 15px;
    padding: 12px 18px;
    margin-right: 0px;
  }
}
@media (max-width: 320px) {
  .main-button {
    font-size: 12px;
  }
}

.main-button2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
  background-color: #171717;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.main-button2:hover{
  color: var(--bgclr) !important;
}

.main-button2 span:not(:nth-child(6)) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  background-color: var(--txt);
  border-radius: 50%;
  transition: .6s ease;
}

.main-button2 span:nth-child(6) {
  position: relative;
}

.main-button2 span:nth-child(1) {
  transform: translate(-3.3em, -4em);
}

.main-button2 span:nth-child(2) {
  transform: translate(-6em, 1.3em);
}

.main-button2 span:nth-child(3) {
  transform: translate(-.2em, 1.8em);
}

.main-button2 span:nth-child(4) {
  transform: translate(3.5em, 1.4em);
}

.main-button2 span:nth-child(5) {
  transform: translate(3.5em, -3.8em);
}

.main-button2:hover span:not(:nth-child(6)) {
  transform: translate(-50%, -50%) scale(8);
  transition: 1.5s ease;
  background-color: var(--txt);
}
  